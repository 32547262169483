import React from 'react';

export default ({ className }: any) => (
  <svg
    className={className}
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 5C9.73478 5 9.48043 5.10536 9.29289 5.29289C9.10536 5.48043 9 5.73478 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5ZM10 13C9.80222 13 9.60888 13.0586 9.44443 13.1685C9.27998 13.2784 9.15181 13.4346 9.07612 13.6173C9.00043 13.8 8.98063 14.0011 9.01921 14.1951C9.0578 14.3891 9.15304 14.5673 9.29289 14.7071C9.43275 14.847 9.61093 14.9422 9.80491 14.9808C9.99889 15.0194 10.2 14.9996 10.3827 14.9239C10.5654 14.8482 10.7216 14.72 10.8315 14.5556C10.9414 14.3911 11 14.1978 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM19.71 5.56L14.44 0.29C14.2484 0.107266 13.9948 0.00368349 13.73 0H6.27C6.00523 0.00368349 5.75163 0.107266 5.56 0.29L0.29 5.56C0.107266 5.75163 0.00368349 6.00523 0 6.27V13.73C0.00368349 13.9948 0.107266 14.2484 0.29 14.44L5.56 19.71C5.75163 19.8927 6.00523 19.9963 6.27 20H13.73C13.9948 19.9963 14.2484 19.8927 14.44 19.71L19.71 14.44C19.8927 14.2484 19.9963 13.9948 20 13.73V6.27C19.9963 6.00523 19.8927 5.75163 19.71 5.56ZM18 13.31L13.31 18H6.69L2 13.31V6.69L6.69 2H13.31L18 6.69V13.31Z'
      fill='#F0142F'
    />
  </svg>
);
