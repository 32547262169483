import React from 'react';

export default () => (
  <svg
    width='82'
    height='82'
    viewBox='0 0 82 82'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='41' cy='41' r='41' fill='#EEF9ED' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.2405 30.2167L35.8003 46.657L29.7595 40.6162C29.4666 40.3234 28.9918 40.3234 28.6988 40.6162L26.9311 42.384C26.6382 42.6769 26.6382 43.1517 26.9311 43.4447L35.2699 51.7835C35.5628 52.0763 36.0376 52.0763 36.3306 51.7835L55.0689 33.0452C55.3618 32.7523 55.3618 32.2774 55.0689 31.9845L53.3011 30.2167C53.0083 29.9239 52.5334 29.9239 52.2405 30.2167Z'
      fill='#29B51C'
    />
  </svg>
);
