import React from 'react';

export default () => (
  <svg
    width='82'
    height='82'
    viewBox='0 0 82 82'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='41' cy='41' r='41' fill='#FCF0ED' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.975 41.0002L50.7075 34.2677C51.0969 33.8783 51.0969 33.2464 50.7075 32.8564L49.1431 31.292C48.7538 30.9027 48.1219 30.9027 47.7319 31.292L41 38.0252L34.2675 31.2927C33.8781 30.9033 33.2463 30.9033 32.8563 31.2927L31.2925 32.8564C30.9031 33.2458 30.9031 33.8777 31.2925 34.2677L38.025 41.0002L31.2925 47.7327C30.9031 48.122 30.9031 48.7539 31.2925 49.1439L32.8569 50.7083C33.2463 51.0977 33.8781 51.0977 34.2681 50.7083L41 43.9752L47.7325 50.7077C48.1219 51.097 48.7538 51.097 49.1438 50.7077L50.7081 49.1433C51.0975 48.7539 51.0975 48.122 50.7081 47.732L43.975 41.0002Z'
      fill='#F0142F'
    />
  </svg>
);
