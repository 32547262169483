import React from 'react';

export default () => (
  <svg
    width='9'
    height='16'
    viewBox='0 0 9 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.97636 15.4545H4.90818V14.1875C7.29455 14.0227 8.63546 12.6136 8.63546 10.8409C8.63546 8.65909 6.56727 7.95455 5.36273 7.63636L4.90818 7.51136V3.47159C6.11273 3.59091 6.98773 4.23864 7.11273 5.27273H8.47636C8.41955 3.61364 6.965 2.35227 4.90818 2.21591V0.90909H3.97636V2.23295C2.0275 2.43182 0.567273 3.67045 0.567273 5.45455C0.567273 7.02273 1.70364 7.95455 3.52182 8.47727L3.97636 8.60795V12.9091C2.78318 12.7841 1.80591 12.1705 1.70364 11H0.249091C0.379773 12.7898 1.74341 14.0398 3.97636 14.1875V15.4545ZM4.90818 12.9091V8.875L4.95364 8.88636C6.18091 9.22727 7.27182 9.65909 7.27182 10.8182C7.27182 11.9602 6.28318 12.7557 4.90818 12.9091ZM3.97636 7.26136C3.11273 7.01705 1.93091 6.53977 1.93091 5.38636C1.93091 4.40341 2.72636 3.65341 3.97636 3.48864V7.26136Z'
      fill='#5A607F'
    />
  </svg>
);
