import React from 'react';

export default () => (
  <svg
    width='31'
    height='6'
    viewBox='0 0 31 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.84364 5.86364C4.22432 5.86364 5.34364 4.74432 5.34364 3.36364C5.34364 1.98295 4.22432 0.863636 2.84364 0.863636C1.46295 0.863636 0.343636 1.98295 0.343636 3.36364C0.343636 4.74432 1.46295 5.86364 2.84364 5.86364ZM11.1524 5.86364C12.5331 5.86364 13.6524 4.74432 13.6524 3.36364C13.6524 1.98295 12.5331 0.863636 11.1524 0.863636C9.7717 0.863636 8.65239 1.98295 8.65239 3.36364C8.65239 4.74432 9.7717 5.86364 11.1524 5.86364ZM19.4611 5.86364C20.8418 5.86364 21.9611 4.74432 21.9611 3.36364C21.9611 1.98295 20.8418 0.863636 19.4611 0.863636C18.0805 0.863636 16.9611 1.98295 16.9611 3.36364C16.9611 4.74432 18.0805 5.86364 19.4611 5.86364ZM27.7699 5.86364C29.1506 5.86364 30.2699 4.74432 30.2699 3.36364C30.2699 1.98295 29.1506 0.863636 27.7699 0.863636C26.3892 0.863636 25.2699 1.98295 25.2699 3.36364C25.2699 4.74432 26.3892 5.86364 27.7699 5.86364Z'
      fill='#7E84A3'
    />
  </svg>
);
