import React from 'react';

export default () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 16C18 17.1042 17.1042 18 16 18C14.8958 18 14 17.1042 14 16C14 14.8958 14.8958 14 16 14C17.1042 14 18 14.8958 18 16ZM23 14C21.8958 14 21 14.8958 21 16C21 17.1042 21.8958 18 23 18C24.1042 18 25 17.1042 25 16C25 14.8958 24.1042 14 23 14ZM9 14C7.89583 14 7 14.8958 7 16C7 17.1042 7.89583 18 9 18C10.1042 18 11 17.1042 11 16C11 14.8958 10.1042 14 9 14Z'
      fill='#131523'
    />
  </svg>
);
