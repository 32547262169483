import React from 'react';

export default () => (
  <svg
    width='16'
    height='14'
    viewBox='0 0 16 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6639 0.777344H1.33611C0.597222 0.777344 0 1.37457 0 2.11068V11.8885C0 12.6246 0.597222 13.2218 1.33611 13.2218H14.6639C15.4028 13.2218 16 12.6246 16 11.8885V2.11068C16 1.37457 15.4028 0.777344 14.6639 0.777344ZM14.4972 11.8885H1.50278C1.41111 11.8885 1.33611 11.8135 1.33611 11.7218V2.27734C1.33611 2.18568 1.41111 2.11068 1.50278 2.11068H14.4972C14.5889 2.11068 14.6639 2.18568 14.6639 2.27734V11.7218C14.6639 11.8135 14.5889 11.8885 14.4972 11.8885ZM5.33333 9.99957V10.2218C5.33333 10.4051 5.18333 10.5551 5 10.5551H3C2.81667 10.5551 2.66667 10.4051 2.66667 10.2218V9.99957C2.66667 9.81623 2.81667 9.66623 3 9.66623H5C5.18333 9.66623 5.33333 9.81623 5.33333 9.99957ZM10.6667 9.99957V10.2218C10.6667 10.4051 10.5167 10.5551 10.3333 10.5551H6.55556C6.37222 10.5551 6.22222 10.4051 6.22222 10.2218V9.99957C6.22222 9.81623 6.37222 9.66623 6.55556 9.66623H10.3333C10.5167 9.66623 10.6667 9.81623 10.6667 9.99957Z'
      fill='#5A607F'
    />
  </svg>
);
