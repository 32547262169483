import React from 'react';

export default () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.8596 18.6246L16.0733 14.8382C16.0016 14.7665 15.908 14.729 15.8082 14.729H15.3965C16.3789 13.5906 16.9747 12.1092 16.9747 10.4873C16.9747 6.9037 14.071 4 10.4873 4C6.9037 4 4 6.9037 4 10.4873C4 14.071 6.9037 16.9747 10.4873 16.9747C12.1092 16.9747 13.5906 16.3789 14.729 15.3965V15.8082C14.729 15.908 14.7696 16.0016 14.8382 16.0733L18.6246 19.8596C18.7711 20.0062 19.0082 20.0062 19.1548 19.8596L19.8596 19.1548C20.0062 19.0082 20.0062 18.7711 19.8596 18.6246ZM10.4873 15.4776C7.73021 15.4776 5.49708 13.2444 5.49708 10.4873C5.49708 7.73021 7.73021 5.49708 10.4873 5.49708C13.2444 5.49708 15.4776 7.73021 15.4776 10.4873C15.4776 13.2444 13.2444 15.4776 10.4873 15.4776Z'
      fill='#7E84A3'
    />
  </svg>
);
