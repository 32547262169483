import React from 'react';

export default () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.8889 17.1852H21.4778L23.1852 15.4778C24.3 14.363 23.5111 12.4445 21.9296 12.4445H19.5592V8.29633C19.5592 7.31485 18.7629 6.51855 17.7815 6.51855H14.2259C13.2444 6.51855 12.4481 7.31485 12.4481 8.29633V12.4445H10.0778C8.49998 12.4445 7.69998 14.3593 8.8222 15.4778L10.5296 17.1852H7.11109C6.12961 17.1852 5.33331 17.9815 5.33331 18.963V23.7037C5.33331 24.6852 6.12961 25.4815 7.11109 25.4815H24.8889C25.8703 25.4815 26.6666 24.6852 26.6666 23.7037V18.963C26.6666 17.9815 25.8703 17.1852 24.8889 17.1852ZM10.0741 14.2223H14.2222V8.29633H17.7778V14.2223H21.9259L16 20.1482L10.0741 14.2223ZM24.8889 23.7037H7.11109V18.963H12.3L14.7407 21.4037C15.437 22.1 16.5592 22.0963 17.2555 21.4037L19.6963 18.963H24.8889V23.7037ZM21.6296 21.3334C21.6296 20.8408 22.0259 20.4445 22.5185 20.4445C23.0111 20.4445 23.4074 20.8408 23.4074 21.3334C23.4074 21.826 23.0111 22.2223 22.5185 22.2223C22.0259 22.2223 21.6296 21.826 21.6296 21.3334Z'
      fill='#008ED1'
    />
  </svg>
);
