import React from 'react';

export default () => (
  <svg
    width='50'
    height='17'
    viewBox='0 0 50 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.5762 0C34.132 0 35.3789 0.358839 36.174 0.692514L35.6306 4.26148L35.271 4.07165C34.5303 3.73797 33.5789 3.41685 32.2665 3.43961C30.6955 3.43961 29.9692 4.17137 29.9692 4.85601C29.9601 5.62742 30.8178 6.13617 32.2206 6.89804C34.5358 8.07348 35.6058 9.49869 35.5907 11.3722C35.5595 14.7909 32.8212 17 28.6028 17C26.8031 16.9793 25.0692 16.5815 24.132 16.1218L24.6953 12.4339L25.2128 12.6968C26.5307 13.3117 27.3841 13.5604 28.9903 13.5604C30.1437 13.5604 31.3817 13.0561 31.3916 11.9522C31.399 11.2313 30.8741 10.7173 29.3119 9.91006C27.7897 9.12216 25.7717 7.80235 25.7941 5.43585C25.8179 2.23453 28.6109 0 32.5762 0ZM0.049926 0.522827H6.52842C7.40082 0.557269 8.10515 0.850561 8.34832 1.83904L9.74472 9.36491L9.74471 9.36679C9.74465 9.3677 9.74458 9.36866 9.74472 9.36905L10.1642 11.6239L14.0976 0.522827H18.3527L12.028 16.7719L7.77696 16.776L4.39292 3.66414C6.40645 4.77797 8.12068 6.06668 9.11345 7.84011C8.85749 7.27896 8.52022 6.64552 8.08866 6.02122C7.61098 5.33023 6.60682 4.44803 6.11895 4.01942L6.047 3.95616C4.46611 2.56466 2.31916 1.44082 0 0.846156L0.049926 0.522827ZM24.206 0.54303H20.0448L17.4418 16.7671H21.6029L24.206 0.54303ZM43.5474 0.54303H46.6998L50 16.7671H46.2163C46.2163 16.7671 45.841 14.9032 45.7189 14.3349C45.4038 14.3349 44.0877 14.3332 42.8503 14.3315H42.85C41.7525 14.3301 40.7169 14.3287 40.4969 14.3287C40.3386 14.7679 39.638 16.767 39.638 16.767H35.3569L41.4119 1.89055C41.8406 0.833385 42.5713 0.54303 43.5474 0.54303ZM43.4884 5.95588C43.6023 5.63627 43.7503 5.22069 43.8571 4.90601V4.90614L44.1307 6.29117C44.1307 6.29117 44.9074 10.1277 45.0696 10.9321L41.7036 10.9342C42.0388 10.0082 43.3197 6.44122 43.3197 6.44122C43.3082 6.46169 43.3839 6.2492 43.4884 5.95588Z'
      fill='#033C72'
    />
  </svg>
);
