export const GET_TOTAL = 'BUDGET/GET_TOTAL';
export const GET_BUDGET = 'BUDGET/GET_BUDGET';
export const GET_BUDGET_FARM = 'BUDGET/GET_BUDGET_FARM';
export const GET_BUDGET_LINE = 'BUDGET/GET_BUDGET_LINE';
export const CREATE_SEEDING = 'BUDGET/CREATE_SEEDING';
export const CREATE_MAINTENANCE = 'BUDGET/CREATE_MAINTENANCE';
export const UPDATE_VALUE = 'BUDGET/UPDATE_VALUE';
export const DELETE_MESSAGE = 'BUDGET/DELETE_MESSAGE';
export const INITIAL_STATE = 'BUDGET/INITIAL_STATE';
export const GET_MESSAGE = 'BUDGET/GET_MESSAGE';
