import React from 'react';

export default () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.6566 20.3267C24.4066 20.0767 24.2666 19.7367 24.2666 19.3833V14.75C24.2666 14.3967 24.4066 14.0567 24.6566 13.8067L26.5099 11.9533C26.7166 11.7467 26.7166 11.4067 26.5099 11.2L26.1333 10.8233C25.9266 10.6167 25.5866 10.6167 25.3799 10.8233L23.5266 12.6767C22.9766 13.2267 22.6666 13.9733 22.6666 14.75V16.2667H21.3333V14.9333C21.3333 14.3433 20.8566 13.8667 20.2666 13.8667H18.8533L16.0399 9.18333C15.7999 8.78333 15.3633 8.53333 14.8966 8.53333H10.6666C10.0766 8.53333 9.59992 9.01333 9.59992 9.59999V13.8667H6.39992C5.80992 13.8667 5.33325 14.3433 5.33325 14.9333V19.2C5.33325 19.79 5.80992 20.2667 6.39992 20.2667C6.39992 22.0333 7.83325 23.4667 9.59992 23.4667C11.3666 23.4667 12.7999 22.0333 12.7999 20.2667H14.9333C14.9333 22.0333 16.3666 23.4667 18.1333 23.4667C19.8999 23.4667 21.3333 22.0333 21.3333 20.2667V17.8667H22.6666V19.3833C22.6666 20.16 22.9766 20.9067 23.5266 21.4567L25.3799 23.31C25.5866 23.5167 25.9266 23.5167 26.1333 23.31L26.5099 22.9333C26.7166 22.7267 26.7166 22.3867 26.5099 22.18L24.6566 20.3267ZM11.1999 10.1333H14.7466L16.9866 13.8667H11.1999V10.1333ZM9.59992 21.8667C8.71659 21.8667 7.99992 21.15 7.99992 20.2667C7.99992 19.3833 8.71659 18.6667 9.59992 18.6667C10.4833 18.6667 11.1999 19.3833 11.1999 20.2667C11.1999 21.15 10.4833 21.8667 9.59992 21.8667ZM12.3566 18.6667C11.8033 17.7133 10.7833 17.0667 9.59992 17.0667C8.48659 17.0667 7.50659 17.6367 6.93325 18.5033V15.4667H19.7333V17.51C19.2599 17.2367 18.7199 17.0667 18.1333 17.0667C16.9533 17.0667 15.9299 17.7133 15.3766 18.6667H12.3566ZM18.1333 21.8667C17.2499 21.8667 16.5333 21.15 16.5333 20.2667C16.5333 19.3833 17.2499 18.6667 18.1333 18.6667C19.0166 18.6667 19.7333 19.3833 19.7333 20.2667C19.7333 21.15 19.0166 21.8667 18.1333 21.8667Z'
      fill='#A75EF1'
    />
  </svg>
);
