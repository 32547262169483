import React from 'react';

export default () => (
  <svg
    width='588'
    height='229'
    viewBox='0 0 588 229'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 185.25H106.119V224.114H150.812V185.25H178.233V147.79H150.812V3.02274H92.3011L0 148.438V185.25ZM106.983 147.79H47.0682V146.063L105.256 53.9773H106.983V147.79Z'
      fill='#D7DBEC'
    />
    <path
      d='M293.025 228.972C348.622 228.972 382.196 186.653 382.304 113.784C382.412 41.4546 348.406 0 293.025 0C237.537 0 203.855 41.3466 203.747 113.784C203.531 186.438 237.321 228.864 293.025 228.972ZM293.025 190.216C267.656 190.216 251.247 164.739 251.355 113.784C251.463 63.5852 267.764 38.3239 293.025 38.3239C318.179 38.3239 334.588 63.5852 334.588 113.784C334.696 164.739 318.287 190.216 293.025 190.216Z'
      fill='#D7DBEC'
    />
    <path
      d='M409.248 185.25H515.367V224.114H560.061V185.25H587.481V147.79H560.061V3.02274H501.549L409.248 148.438V185.25ZM516.231 147.79H456.316V146.063L514.504 53.9773H516.231V147.79Z'
      fill='#D7DBEC'
    />
  </svg>
);
