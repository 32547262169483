import React from 'react';

export default () => (
  <svg
    width='11'
    height='13'
    viewBox='0 0 11 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.47636 9.81818C6.47636 11.0909 7.27182 12.1591 8.74909 12.1591C10.2036 12.1591 10.9991 11.0909 10.9991 9.81818V9.20455C10.9991 7.93182 10.2264 6.86364 8.74909 6.86364C7.29455 6.86364 6.47636 7.93182 6.47636 9.20455V9.81818ZM0.680909 3.15909C0.680909 4.43182 1.47636 5.5 2.95364 5.5C4.40818 5.5 5.20364 4.43182 5.20364 3.15909V2.54545C5.20364 1.27273 4.43091 0.204545 2.95364 0.204545C1.49909 0.204545 0.680909 1.27273 0.680909 2.54545V3.15909ZM1.11273 12H2.40818L10.4082 0.363636H9.11273L1.11273 12ZM7.63546 9.20455C7.63546 8.5 7.95364 7.84091 8.74909 7.84091C9.56727 7.84091 9.84 8.5 9.84 9.20455V9.81818C9.84 10.5227 9.54455 11.1818 8.74909 11.1818C7.93091 11.1818 7.63546 10.5227 7.63546 9.81818V9.20455ZM1.84 2.54545C1.84 1.84091 2.15818 1.18182 2.95364 1.18182C3.77182 1.18182 4.04455 1.84091 4.04455 2.54545V3.15909C4.04455 3.86364 3.74909 4.52273 2.95364 4.52273C2.13545 4.52273 1.84 3.86364 1.84 3.15909V2.54545Z'
      fill='#5A607F'
    />
  </svg>
);
