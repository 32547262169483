export const GET_USERS = 'USERS/GET_USERS';
export const GET_USER = 'USERS/GET_USER';
export const EDIT_USER = 'USERS/EDIT_USER';
export const ADD_USER = 'USERS/ADD_USER';
export const GET_MESSAGE = 'USERS/GET_MESSAGE';
export const DELETE_MESSAGE = 'USERS/DELETE_MESSAGE';
export const DELETE_USER = 'USERS/DELETE_USER';
export const DEACTIVATE_USER = 'USERS/DEACTIVATE_USER';
export const ACTIVATE_USER = 'USERS/ACTIVATE_USER';
export const INITIAL_USER = 'USERS/INITIAL_USER';
