import React from 'react';

export default () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 5.66669C10.2916 5.66669 5.66663 10.2917 5.66663 16C5.66663 21.7084 10.2916 26.3334 16 26.3334C21.7083 26.3334 26.3333 21.7084 26.3333 16C26.3333 10.2917 21.7083 5.66669 16 5.66669ZM16 24.3334C11.3958 24.3334 7.66663 20.6042 7.66663 16C7.66663 11.3959 11.3958 7.66669 16 7.66669C20.6041 7.66669 24.3333 11.3959 24.3333 16C24.3333 20.6042 20.6041 24.3334 16 24.3334ZM18.575 19.9834L15.0375 17.4125C14.9083 17.3167 14.8333 17.1667 14.8333 17.0084V10.1667C14.8333 9.89169 15.0583 9.66669 15.3333 9.66669H16.6666C16.9416 9.66669 17.1666 9.89169 17.1666 10.1667V16.0709L19.95 18.0959C20.175 18.2584 20.2208 18.5709 20.0583 18.7959L19.275 19.875C19.1125 20.0959 18.8 20.1459 18.575 19.9834Z'
      fill='#63D24D'
    />
  </svg>
);
