import React from 'react';

export default () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 1.5C12.4853 1.5 14.5 3.51472 14.5 6C14.5 8.48528 12.4853 10.5 10 10.5C9.40725 10.5 8.84138 10.3852 8.32312 10.1769L7 11.5H6V13H4.5V14.5H1.5V11.5L5.69303 7.30697C5.56472 6.88319 5.49968 6.44278 5.5 6C5.5 3.51472 7.51472 1.5 10 1.5ZM10 0C6.68641 0 4 2.68606 4 6C4 6.276 4.01881 6.55072 4.05622 6.82244L0.219656 10.659C0.0790202 10.7997 9.91353e-06 10.9904 0 11.1893V15.25C0 15.6642 0.335781 16 0.75 16H5.25C5.66422 16 6 15.6642 6 15.25V14.5H6.75C7.16422 14.5 7.5 14.1642 7.5 13.75V13.125L8.75153 11.8698C9.15956 11.9564 9.57691 12 10 12C13.3136 12 16 9.31394 16 6C16 2.68641 13.3139 0 10 0ZM10 4.5C10 5.32844 10.6716 6 11.5 6C12.3284 6 13 5.32844 13 4.5C13 3.67156 12.3284 3 11.5 3C10.6716 3 10 3.67156 10 4.5Z'
      fill='#5A607F'
    />
  </svg>
);
