import React from 'react';

interface IOwnProps {
  active: boolean;
}

export default ({ active }: IOwnProps) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.29412 10.6154H25.7059C26.0491 10.6154 26.3783 10.4776 26.621 10.2324C26.8637 9.98713 27 9.65451 27 9.30769C27 8.96087 26.8637 8.62825 26.621 8.38301C26.3783 8.13777 26.0491 8 25.7059 8H6.29412C5.9509 8 5.62173 8.13777 5.37904 8.38301C5.13634 8.62825 5 8.96087 5 9.30769C5 9.65451 5.13634 9.98713 5.37904 10.2324C5.62173 10.4776 5.9509 10.6154 6.29412 10.6154ZM11.4706 22.3846C11.1274 22.3846 10.7982 22.5224 10.5555 22.7676C10.3128 23.0129 10.1765 23.3455 10.1765 23.6923C10.1765 24.0391 10.3128 24.3717 10.5555 24.617C10.7982 24.8622 11.1274 25 11.4706 25H20.5294C20.8726 25 21.2018 24.8622 21.4445 24.617C21.6872 24.3717 21.8235 24.0391 21.8235 23.6923C21.8235 23.3455 21.6872 23.0129 21.4445 22.7676C21.2018 22.5224 20.8726 22.3846 20.5294 22.3846H11.4706ZM23.1176 15.1923H8.88235C8.53913 15.1923 8.20997 15.3301 7.96727 15.5753C7.72458 15.8206 7.58824 16.1532 7.58824 16.5C7.58824 16.8468 7.72458 17.1794 7.96727 17.4247C8.20997 17.6699 8.53913 17.8077 8.88235 17.8077H23.1176C23.4609 17.8077 23.79 17.6699 24.0327 17.4247C24.2754 17.1794 24.4118 16.8468 24.4118 16.5C24.4118 16.1532 24.2754 15.8206 24.0327 15.5753C23.79 15.3301 23.4609 15.1923 23.1176 15.1923Z'
      fill={active ? '#07689F' : '#131523'}
    />
  </svg>
);
