import React from 'react';

export default () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 10.5C10.7312 10.5 10.5125 10.4531 9.74375 10.7125C9.19687 10.8969 8.60938 11 8 11C7.39062 11 6.80312 10.8969 6.25625 10.7125C5.4875 10.4531 5.27187 10.5 4 10.5C1.79063 10.5 0 12.2906 0 14.5V15C0 15.5531 0.446875 16 1 16H15C15.5531 16 16 15.5531 16 15V14.5C16 12.2906 14.2094 10.5 12 10.5ZM14.5 14.5H1.5C1.5 13.8313 1.75937 13.2031 2.23125 12.7312C2.70312 12.2594 3.33125 12 4 12C5.28438 12 5.28125 11.9656 5.775 12.1312C6.49375 12.375 7.24375 12.5 8 12.5C8.75625 12.5 9.50625 12.375 10.225 12.1312C10.7188 11.9625 10.7156 12 12 12C13.3781 12 14.5 13.1219 14.5 14.5ZM8 10C10.7625 10 13 7.7625 13 5C13 2.2375 10.7625 0 8 0C5.2375 0 3 2.2375 3 5C3 7.7625 5.2375 10 8 10ZM8 1.5C9.93125 1.5 11.5 3.06875 11.5 5C11.5 6.93125 9.93125 8.5 8 8.5C6.06875 8.5 4.5 6.93125 4.5 5C4.5 3.06875 6.06875 1.5 8 1.5Z'
      fill='#5A607F'
    />
  </svg>
);
