import React, { useState } from 'react';

import User from '../components/users/User';

const AddUsers = () => {
  return (
    <>
      <User />
    </>
  );
};

export default AddUsers;
