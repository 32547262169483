import React from 'react';

export default () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.4814 8.31478C10.7899 8.31621 11.8503 9.37663 11.8518 10.6852V12.0714L12.3795 11.0163C12.6256 10.5248 13.0346 10.134 13.5366 9.91034L13.5507 9.90404C13.1492 7.8034 11.2156 6.35294 9.08652 6.55532C6.95745 6.7577 5.33177 8.54649 5.33325 10.6852V24.9074C5.33325 25.2347 5.59857 25.5 5.92585 25.5H10.074V23.7222H7.11103V10.6852C7.11246 9.37663 8.17287 8.31621 9.4814 8.31478ZM20.1481 14.8333H17.7777C17.4504 14.8333 17.1851 15.0986 17.1851 15.4259V17.7963C17.1851 18.1235 17.4504 18.3889 17.7777 18.3889H20.1481C20.4753 18.3889 20.7407 18.1235 20.7407 17.7963V15.4259C20.7407 15.0986 20.4753 14.8333 20.1481 14.8333ZM26.5414 15.6563L24.4862 11.5452C24.3633 11.2993 24.1588 11.1039 23.9077 10.9922L19.4444 9.0096C19.1378 8.87332 18.7879 8.87332 18.4814 9.0096L14.0181 10.9933C13.8266 11.0785 13.6107 11.2896 13.6295 11.2896C13.5547 11.3663 13.4908 11.4529 13.4396 11.547L11.3844 15.6581C11.3024 15.8223 11.2595 16.0032 11.2592 16.1866V25.5H26.074C26.4013 25.5 26.6666 25.2347 26.6666 24.9074V16.1866C26.6665 16.0025 26.6237 15.821 26.5414 15.6563ZM24.8888 23.7222H20.7407V21.3518C20.7407 21.0245 20.4753 20.7592 20.1481 20.7592H17.7777C17.4504 20.7592 17.1851 21.0245 17.1851 21.3518V23.7222H13.037V16.3263L14.9344 12.5314L18.9629 10.7411L22.9914 12.5314L24.8888 16.3263V23.7222Z'
      fill='#FF8914'
    />
  </svg>
);
