import React from 'react';

export default () => (
  <svg
    width='8'
    height='14'
    viewBox='0 0 8 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.772065 0.190662L0.153346 0.80935C0.0069087 0.955787 0.0069087 1.19322 0.153346 1.33969L5.80019 7.00001L0.153346 12.6603C0.0069087 12.8068 0.0069087 13.0442 0.153346 13.1907L0.772065 13.8094C0.918502 13.9558 1.15594 13.9558 1.30241 13.8094L7.8466 7.26519C7.99303 7.11876 7.99303 6.88132 7.8466 6.73485L1.30241 0.190662C1.15594 0.0441934 0.918502 0.0441934 0.772065 0.190662Z'
      fill='white'
    />
  </svg>
);
